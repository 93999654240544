require('./../assets/vendor/create-js/createjs');

require('./../assets/vendor/modernizr/modernizr');

// import lodash from 'lodash';

import $jQuery from 'jquery';

// import axios from 'axios';

(function (global, factory) {
    'use strict';

    if (typeof (module) === 'object' && typeof (module.exports) === 'object') {
        /**
         *
         * For CommonJS and CommonJS-like environments where a proper `window`
         * is present, execute the factory and get Class.
         * For environments that do not have a `window` with a `document`
         * (such as Node.js), expose a factory as module.exports.
         * This accentuates the need for the creation of a real `window`.
         * e.g. var Class = require('Class')({window: window});
         * @type {void|(function(*): void)}
         */
        module.exports = global.document ?
            factory(global, true) :
            function (w) {
                if (!w.document) {
                    throw new Error('Class requires a window with a document');
                }
                return factory(w, false);
            };
    } else {
        factory(global, false);
    }
})(typeof (window) !== 'undefined' ? window : this, function (window, noGlobal) {
    if (!noGlobal) {
        // window._ = lodash;

        window.$ = window.jQuery = $jQuery;

        /**
         * We'll load the axios HTTP library which allows us to easily issue requests.
         */
        // window.axios = axios;

        /** @namespace axios.defaults */
        // window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }
});

require('jquery-confirm');

